import api from "../axios_service.js";

export async function fetchHomens() {
  const response = await api.get("/reports/users/homens");
  return response.data.result;
}

export async function fetchMulheres() {
  const response = await api.get("/reports/users/mulheres");
  return response.data.result;
}

export async function fetchGeral() {
  const response = await api.get("/reports/users/geral");
  return response.data.result;
}
